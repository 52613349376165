/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A hotel chain
 * @export
 */
export const HotelChain = {
    Marriott: 'Marriott',
    Hilton: 'Hilton',
    Hyatt: 'Hyatt',
    Ihg: 'IHG',
    Wyndham: 'Wyndham',
    Choice: 'Choice',
    Accor: 'Accor'
} as const;
export type HotelChain = typeof HotelChain[keyof typeof HotelChain];


export function instanceOfHotelChain(value: any): boolean {
    for (const key in HotelChain) {
        if (Object.prototype.hasOwnProperty.call(HotelChain, key)) {
            if ((HotelChain as Record<string, HotelChain>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function HotelChainFromJSON(json: any): HotelChain {
    return HotelChainFromJSONTyped(json, false);
}

export function HotelChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelChain {
    return json as HotelChain;
}

export function HotelChainToJSON(value?: HotelChain | null): any {
    return value as any;
}
