/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { HotelChain } from './HotelChain';
import {
    HotelChainFromJSON,
    HotelChainFromJSONTyped,
    HotelChainToJSON,
} from './HotelChain';
import type { CashCostPercentile } from './CashCostPercentile';
import {
    CashCostPercentileFromJSON,
    CashCostPercentileFromJSONTyped,
    CashCostPercentileToJSON,
} from './CashCostPercentile';
import type { RedemptionRatePercentile } from './RedemptionRatePercentile';
import {
    RedemptionRatePercentileFromJSON,
    RedemptionRatePercentileFromJSONTyped,
    RedemptionRatePercentileToJSON,
} from './RedemptionRatePercentile';

/**
 * Hotel stats
 * @export
 * @interface HotelStats
 */
export interface HotelStats {
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {HotelChain}
     * @memberof HotelStats
     */
    chainName?: HotelChain;
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    nightlyCashCost: number;
    /**
     * 
     * @type {string}
     * @memberof HotelStats
     */
    nightlyCashCostCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    nightlyCashCostUsd: number;
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    nightlyPointsCost?: number;
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    redemptionRatePercentile?: number;
    /**
     * Redemption rate in cents/pt
     * @type {number}
     * @memberof HotelStats
     */
    redemptionRate?: number;
    /**
     * 
     * @type {Array<RedemptionRatePercentile>}
     * @memberof HotelStats
     */
    redemptionRateDistribution?: Array<RedemptionRatePercentile>;
    /**
     * 
     * @type {number}
     * @memberof HotelStats
     */
    cashCostPercentile?: number;
    /**
     * 
     * @type {Array<CashCostPercentile>}
     * @memberof HotelStats
     */
    cashCostDistribution?: Array<CashCostPercentile>;
}

/**
 * Check if a given object implements the HotelStats interface.
 */
export function instanceOfHotelStats(value: object): value is HotelStats {
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('nightlyCashCost' in value) || value['nightlyCashCost'] === undefined) return false;
    if (!('nightlyCashCostCurrency' in value) || value['nightlyCashCostCurrency'] === undefined) return false;
    if (!('nightlyCashCostUsd' in value) || value['nightlyCashCostUsd'] === undefined) return false;
    return true;
}

export function HotelStatsFromJSON(json: any): HotelStats {
    return HotelStatsFromJSONTyped(json, false);
}

export function HotelStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HotelStats {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'],
        'chainName': json['chain_name'] == null ? undefined : HotelChainFromJSON(json['chain_name']),
        'nightlyCashCost': json['nightly_cash_cost'],
        'nightlyCashCostCurrency': json['nightly_cash_cost_currency'],
        'nightlyCashCostUsd': json['nightly_cash_cost_usd'],
        'nightlyPointsCost': json['nightly_points_cost'] == null ? undefined : json['nightly_points_cost'],
        'redemptionRatePercentile': json['redemption_rate_percentile'] == null ? undefined : json['redemption_rate_percentile'],
        'redemptionRate': json['redemption_rate'] == null ? undefined : json['redemption_rate'],
        'redemptionRateDistribution': json['redemption_rate_distribution'] == null ? undefined : ((json['redemption_rate_distribution'] as Array<any>).map(RedemptionRatePercentileFromJSON)),
        'cashCostPercentile': json['cash_cost_percentile'] == null ? undefined : json['cash_cost_percentile'],
        'cashCostDistribution': json['cash_cost_distribution'] == null ? undefined : ((json['cash_cost_distribution'] as Array<any>).map(CashCostPercentileFromJSON)),
    };
}

export function HotelStatsToJSON(value?: HotelStats | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'chain_name': HotelChainToJSON(value['chainName']),
        'nightly_cash_cost': value['nightlyCashCost'],
        'nightly_cash_cost_currency': value['nightlyCashCostCurrency'],
        'nightly_cash_cost_usd': value['nightlyCashCostUsd'],
        'nightly_points_cost': value['nightlyPointsCost'],
        'redemption_rate_percentile': value['redemptionRatePercentile'],
        'redemption_rate': value['redemptionRate'],
        'redemption_rate_distribution': value['redemptionRateDistribution'] == null ? undefined : ((value['redemptionRateDistribution'] as Array<any>).map(RedemptionRatePercentileToJSON)),
        'cash_cost_percentile': value['cashCostPercentile'],
        'cash_cost_distribution': value['cashCostDistribution'] == null ? undefined : ((value['cashCostDistribution'] as Array<any>).map(CashCostPercentileToJSON)),
    };
}

