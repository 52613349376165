/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RawRateSummaryCharge } from './RawRateSummaryCharge';
import {
    RawRateSummaryChargeFromJSON,
    RawRateSummaryChargeFromJSONTyped,
    RawRateSummaryChargeToJSON,
} from './RawRateSummaryCharge';
import type { Perks } from './Perks';
import {
    PerksFromJSON,
    PerksFromJSONTyped,
    PerksToJSON,
} from './Perks';
import type { PointsAmount } from './PointsAmount';
import {
    PointsAmountFromJSON,
    PointsAmountFromJSONTyped,
    PointsAmountToJSON,
} from './PointsAmount';
import type { MerchantOfRecord } from './MerchantOfRecord';
import {
    MerchantOfRecordFromJSON,
    MerchantOfRecordFromJSONTyped,
    MerchantOfRecordToJSON,
} from './MerchantOfRecord';
import type { RateType } from './RateType';
import {
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';
import type { CurrencyAmount } from './CurrencyAmount';
import {
    CurrencyAmountFromJSON,
    CurrencyAmountFromJSONTyped,
    CurrencyAmountToJSON,
} from './CurrencyAmount';

/**
 * 
 * @export
 * @interface RawRateSummary
 */
export interface RawRateSummary {
    /**
     * 
     * @type {string}
     * @memberof RawRateSummary
     */
    rateId: string;
    /**
     * If the rate is refundable or not
     * @type {boolean}
     * @memberof RawRateSummary
     */
    refundable?: boolean;
    /**
     * If the rate is commissionable or not
     * @type {boolean}
     * @memberof RawRateSummary
     */
    commissionable?: boolean;
    /**
     * If the rate is accessible or not
     * @type {boolean}
     * @memberof RawRateSummary
     */
    accessible?: boolean;
    /**
     * If the rate is a member rate or not
     * @type {boolean}
     * @memberof RawRateSummary
     */
    memberRate?: boolean;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    totalRoomBaseRate?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    averageNightlyRate?: CurrencyAmount;
    /**
     * 
     * @type {PointsAmount}
     * @memberof RawRateSummary
     */
    averageNightlyPointsRate?: PointsAmount;
    /**
     * 
     * @type {Array<RawRateSummaryCharge>}
     * @memberof RawRateSummary
     */
    nightlyRoomBaseRates: Array<RawRateSummaryCharge>;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    taxesAndFees?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    propertyFee?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    subTotal?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    totalDueToday?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    totalDueAtProperty?: CurrencyAmount;
    /**
     * 
     * @type {Array<RawRateSummaryCharge>}
     * @memberof RawRateSummary
     */
    deposits: Array<RawRateSummaryCharge>;
    /**
     * 
     * @type {Array<RawRateSummaryCharge>}
     * @memberof RawRateSummary
     */
    chargesDueAtProperty: Array<RawRateSummaryCharge>;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    totalCash?: CurrencyAmount;
    /**
     * 
     * @type {PointsAmount}
     * @memberof RawRateSummary
     */
    totalPoints?: PointsAmount;
    /**
     * 
     * @type {MerchantOfRecord}
     * @memberof RawRateSummary
     */
    merchantOfRecord: MerchantOfRecord;
    /**
     * If refundable, simplified information about cancellation. This is not the full cancellation policy.
     * @type {string}
     * @memberof RawRateSummary
     */
    cancellationSummary?: string;
    /**
     * The full cancellation policy
     * @type {Array<string>}
     * @memberof RawRateSummary
     */
    cancellationPolicyFull?: Array<string>;
    /**
     * Loyalty earnings in points - deprecated
     * @type {number}
     * @memberof RawRateSummary
     */
    earnings?: number;
    /**
     * 
     * @type {PointsAmount}
     * @memberof RawRateSummary
     */
    earningsPoints?: PointsAmount;
    /**
     * The number of nights earned for a given stay
     * @type {number}
     * @memberof RawRateSummary
     */
    statusNights?: number;
    /**
     * 
     * @type {RateType}
     * @memberof RawRateSummary
     */
    rateType: RateType;
    /**
     * The rate token required for a price check
     * @type {string}
     * @memberof RawRateSummary
     */
    rateToken?: string;
    /**
     * The booking token required for a booking
     * @type {string}
     * @memberof RawRateSummary
     */
    bookingToken?: string;
    /**
     * The rate name as defined by the supplier
     * @type {string}
     * @memberof RawRateSummary
     */
    supplierRateName?: string;
    /**
     * Additional rate information
     * @type {Array<string>}
     * @memberof RawRateSummary
     */
    additionalRateInfo?: Array<string>;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    gondolaEarnings?: CurrencyAmount;
    /**
     * 
     * @type {CurrencyAmount}
     * @memberof RawRateSummary
     */
    totalEarnings?: CurrencyAmount;
    /**
     * 
     * @type {Perks}
     * @memberof RawRateSummary
     */
    perks?: Perks;
}

/**
 * Check if a given object implements the RawRateSummary interface.
 */
export function instanceOfRawRateSummary(value: object): value is RawRateSummary {
    if (!('rateId' in value) || value['rateId'] === undefined) return false;
    if (!('nightlyRoomBaseRates' in value) || value['nightlyRoomBaseRates'] === undefined) return false;
    if (!('deposits' in value) || value['deposits'] === undefined) return false;
    if (!('chargesDueAtProperty' in value) || value['chargesDueAtProperty'] === undefined) return false;
    if (!('merchantOfRecord' in value) || value['merchantOfRecord'] === undefined) return false;
    if (!('rateType' in value) || value['rateType'] === undefined) return false;
    return true;
}

export function RawRateSummaryFromJSON(json: any): RawRateSummary {
    return RawRateSummaryFromJSONTyped(json, false);
}

export function RawRateSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RawRateSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'rateId': json['rate_id'],
        'refundable': json['refundable'] == null ? undefined : json['refundable'],
        'commissionable': json['commissionable'] == null ? undefined : json['commissionable'],
        'accessible': json['accessible'] == null ? undefined : json['accessible'],
        'memberRate': json['member_rate'] == null ? undefined : json['member_rate'],
        'totalRoomBaseRate': json['total_room_base_rate'] == null ? undefined : CurrencyAmountFromJSON(json['total_room_base_rate']),
        'averageNightlyRate': json['average_nightly_rate'] == null ? undefined : CurrencyAmountFromJSON(json['average_nightly_rate']),
        'averageNightlyPointsRate': json['average_nightly_points_rate'] == null ? undefined : PointsAmountFromJSON(json['average_nightly_points_rate']),
        'nightlyRoomBaseRates': ((json['nightly_room_base_rates'] as Array<any>).map(RawRateSummaryChargeFromJSON)),
        'taxesAndFees': json['taxes_and_fees'] == null ? undefined : CurrencyAmountFromJSON(json['taxes_and_fees']),
        'propertyFee': json['property_fee'] == null ? undefined : CurrencyAmountFromJSON(json['property_fee']),
        'subTotal': json['sub_total'] == null ? undefined : CurrencyAmountFromJSON(json['sub_total']),
        'totalDueToday': json['total_due_today'] == null ? undefined : CurrencyAmountFromJSON(json['total_due_today']),
        'totalDueAtProperty': json['total_due_at_property'] == null ? undefined : CurrencyAmountFromJSON(json['total_due_at_property']),
        'deposits': ((json['deposits'] as Array<any>).map(RawRateSummaryChargeFromJSON)),
        'chargesDueAtProperty': ((json['charges_due_at_property'] as Array<any>).map(RawRateSummaryChargeFromJSON)),
        'totalCash': json['total_cash'] == null ? undefined : CurrencyAmountFromJSON(json['total_cash']),
        'totalPoints': json['total_points'] == null ? undefined : PointsAmountFromJSON(json['total_points']),
        'merchantOfRecord': MerchantOfRecordFromJSON(json['merchant_of_record']),
        'cancellationSummary': json['cancellation_summary'] == null ? undefined : json['cancellation_summary'],
        'cancellationPolicyFull': json['cancellation_policy_full'] == null ? undefined : json['cancellation_policy_full'],
        'earnings': json['earnings'] == null ? undefined : json['earnings'],
        'earningsPoints': json['earnings_points'] == null ? undefined : PointsAmountFromJSON(json['earnings_points']),
        'statusNights': json['status_nights'] == null ? undefined : json['status_nights'],
        'rateType': RateTypeFromJSON(json['rate_type']),
        'rateToken': json['rate_token'] == null ? undefined : json['rate_token'],
        'bookingToken': json['booking_token'] == null ? undefined : json['booking_token'],
        'supplierRateName': json['supplier_rate_name'] == null ? undefined : json['supplier_rate_name'],
        'additionalRateInfo': json['additional_rate_info'] == null ? undefined : json['additional_rate_info'],
        'gondolaEarnings': json['gondola_earnings'] == null ? undefined : CurrencyAmountFromJSON(json['gondola_earnings']),
        'totalEarnings': json['total_earnings'] == null ? undefined : CurrencyAmountFromJSON(json['total_earnings']),
        'perks': json['perks'] == null ? undefined : PerksFromJSON(json['perks']),
    };
}

export function RawRateSummaryToJSON(value?: RawRateSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'rate_id': value['rateId'],
        'refundable': value['refundable'],
        'commissionable': value['commissionable'],
        'accessible': value['accessible'],
        'member_rate': value['memberRate'],
        'total_room_base_rate': CurrencyAmountToJSON(value['totalRoomBaseRate']),
        'average_nightly_rate': CurrencyAmountToJSON(value['averageNightlyRate']),
        'average_nightly_points_rate': PointsAmountToJSON(value['averageNightlyPointsRate']),
        'nightly_room_base_rates': ((value['nightlyRoomBaseRates'] as Array<any>).map(RawRateSummaryChargeToJSON)),
        'taxes_and_fees': CurrencyAmountToJSON(value['taxesAndFees']),
        'property_fee': CurrencyAmountToJSON(value['propertyFee']),
        'sub_total': CurrencyAmountToJSON(value['subTotal']),
        'total_due_today': CurrencyAmountToJSON(value['totalDueToday']),
        'total_due_at_property': CurrencyAmountToJSON(value['totalDueAtProperty']),
        'deposits': ((value['deposits'] as Array<any>).map(RawRateSummaryChargeToJSON)),
        'charges_due_at_property': ((value['chargesDueAtProperty'] as Array<any>).map(RawRateSummaryChargeToJSON)),
        'total_cash': CurrencyAmountToJSON(value['totalCash']),
        'total_points': PointsAmountToJSON(value['totalPoints']),
        'merchant_of_record': MerchantOfRecordToJSON(value['merchantOfRecord']),
        'cancellation_summary': value['cancellationSummary'],
        'cancellation_policy_full': value['cancellationPolicyFull'],
        'earnings': value['earnings'],
        'earnings_points': PointsAmountToJSON(value['earningsPoints']),
        'status_nights': value['statusNights'],
        'rate_type': RateTypeToJSON(value['rateType']),
        'rate_token': value['rateToken'],
        'booking_token': value['bookingToken'],
        'supplier_rate_name': value['supplierRateName'],
        'additional_rate_info': value['additionalRateInfo'],
        'gondola_earnings': CurrencyAmountToJSON(value['gondolaEarnings']),
        'total_earnings': CurrencyAmountToJSON(value['totalEarnings']),
        'perks': PerksToJSON(value['perks']),
    };
}

