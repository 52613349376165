import { JWTDecodeParams, JWTEncodeParams } from "next-auth/jwt";
import jwt from "jsonwebtoken";
import { JWT } from "next-auth/jwt";
import { toast } from "react-toastify";
import { captureException } from "@sentry/nextjs";

const algo: jwt.Algorithm = "HS256";
const defaultMaxAge = 30 * 24 * 60 * 60; // 30 days

export const jwtEncode = ({
  secret,
  token,
  maxAge = defaultMaxAge,
}: JWTEncodeParams) => {
  const jwtClaims: JWT = {
    ...token,
    iat: Date.now() / 1000,
    exp: Date.now() / 1000 + maxAge,
  };
  return jwt.sign(jwtClaims, secret, { algorithm: algo });
};

export const jwtDecode = ({ token, secret }: JWTDecodeParams) =>
  token ? (jwt.verify(token, secret, { algorithms: [algo] }) as JWT) : null;

export const updateSessionTokenWithCookieUserId = ({
  token,
  jwtSecret,
  userId,
}: {
  token: string;
  jwtSecret: string;
  userId: string;
}): string => {
  const jwt = jwtDecode({ token, secret: jwtSecret });
  if (jwt?.email && !hasAdminAccess(jwt.email)) {
    return token;
  }
  const newJwt = { ...jwt };
  newJwt.sub = userId;
  return jwtEncode({ token: newJwt, secret: jwtSecret });
};

export const getPublicUserToken = async () => {
  const sub = "public_user";
  return {
    token: jwtEncode({
      secret: process.env.JWT_SECRET_KEY!,
      token: { sub },
    }),
    userId: sub,
  };
};

export const validPublicHostUrls = [
  "localhost:3000",
  "www.gondola.ai",
  "staging.gondola.ai",
];

export const hasAdminAccess = (email?: string) => {
  if (
    email?.split("@")[1] === "gondola.ai" ||
    email === "samarthmn.work@gmail.com"
  ) {
    return true;
  }
  return false;
};

type HandleErrorOptions = {
  reportError?: boolean;
};

type HandleError = (
  error: unknown | Error | string,
  options?: HandleErrorOptions
) => void;

export const handleError: HandleError = (error, options) => {
  if (typeof error === "string") {
    toast.error(error);
    options?.reportError && reportError(new Error(error));
  } else if (typeof error === "object" && "message" in (error as Error)) {
    toast.error((error as Error).message);
    options?.reportError && reportError(error as Error);
  } else {
    toast.error("An error occurred");
    options?.reportError && reportError(error);
  }
};

export const reportError = (error: unknown | Error) => {
  captureException(error);
};

export const searchAndBookTestEnv =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "development" ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === "preview";
