/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Perks for a hotel
 * @export
 * @interface Perks
 */
export interface Perks {
    /**
     * Bar discount percentage
     * @type {number}
     * @memberof Perks
     */
    barDiscountPercentage?: number;
    /**
     * Complimentary parking
     * @type {boolean}
     * @memberof Perks
     */
    parking?: boolean;
    /**
     * Complimentary breakfast
     * @type {boolean}
     * @memberof Perks
     */
    breakfast?: boolean;
    /**
     * Fitness center availability
     * @type {boolean}
     * @memberof Perks
     */
    fitnessCenter?: boolean;
    /**
     * Free WiFi availability
     * @type {boolean}
     * @memberof Perks
     */
    freeWifi?: boolean;
    /**
     * Allows early checkout
     * @type {boolean}
     * @memberof Perks
     */
    allowsEarlyCheckout?: boolean;
    /**
     * Welcome perks
     * @type {boolean}
     * @memberof Perks
     */
    welcome?: boolean;
    /**
     * Type of breakfast
     * @type {string}
     * @memberof Perks
     */
    breakfastType?: string;
    /**
     * Same day cancellation availability
     * @type {boolean}
     * @memberof Perks
     */
    sameDayCancellation?: boolean;
    /**
     * Early check-in availability
     * @type {boolean}
     * @memberof Perks
     */
    earlyCheckin?: boolean;
    /**
     * Late checkout availability
     * @type {boolean}
     * @memberof Perks
     */
    lateCheckout?: boolean;
    /**
     * Upgrade availability
     * @type {boolean}
     * @memberof Perks
     */
    upgrade?: boolean;
}

/**
 * Check if a given object implements the Perks interface.
 */
export function instanceOfPerks(value: object): value is Perks {
    return true;
}

export function PerksFromJSON(json: any): Perks {
    return PerksFromJSONTyped(json, false);
}

export function PerksFromJSONTyped(json: any, ignoreDiscriminator: boolean): Perks {
    if (json == null) {
        return json;
    }
    return {
        
        'barDiscountPercentage': json['bar_discount_percentage'] == null ? undefined : json['bar_discount_percentage'],
        'parking': json['parking'] == null ? undefined : json['parking'],
        'breakfast': json['breakfast'] == null ? undefined : json['breakfast'],
        'fitnessCenter': json['fitness_center'] == null ? undefined : json['fitness_center'],
        'freeWifi': json['free_wifi'] == null ? undefined : json['free_wifi'],
        'allowsEarlyCheckout': json['allows_early_checkout'] == null ? undefined : json['allows_early_checkout'],
        'welcome': json['welcome'] == null ? undefined : json['welcome'],
        'breakfastType': json['breakfast_type'] == null ? undefined : json['breakfast_type'],
        'sameDayCancellation': json['same_day_cancellation'] == null ? undefined : json['same_day_cancellation'],
        'earlyCheckin': json['early_checkin'] == null ? undefined : json['early_checkin'],
        'lateCheckout': json['late_checkout'] == null ? undefined : json['late_checkout'],
        'upgrade': json['upgrade'] == null ? undefined : json['upgrade'],
    };
}

export function PerksToJSON(value?: Perks | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bar_discount_percentage': value['barDiscountPercentage'],
        'parking': value['parking'],
        'breakfast': value['breakfast'],
        'fitness_center': value['fitnessCenter'],
        'free_wifi': value['freeWifi'],
        'allows_early_checkout': value['allowsEarlyCheckout'],
        'welcome': value['welcome'],
        'breakfast_type': value['breakfastType'],
        'same_day_cancellation': value['sameDayCancellation'],
        'early_checkin': value['earlyCheckin'],
        'late_checkout': value['lateCheckout'],
        'upgrade': value['upgrade'],
    };
}

