/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * 
 * @export
 * @interface CashSummary
 */
export interface CashSummary {
    /**
     * 
     * @type {RawRateSummary}
     * @memberof CashSummary
     */
    lowestCashRate: RawRateSummary;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof CashSummary
     */
    cashDealScore?: number;
}

/**
 * Check if a given object implements the CashSummary interface.
 */
export function instanceOfCashSummary(value: object): value is CashSummary {
    if (!('lowestCashRate' in value) || value['lowestCashRate'] === undefined) return false;
    return true;
}

export function CashSummaryFromJSON(json: any): CashSummary {
    return CashSummaryFromJSONTyped(json, false);
}

export function CashSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CashSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'lowestCashRate': RawRateSummaryFromJSON(json['lowest_cash_rate']),
        'cashDealScore': json['cash_deal_score'] == null ? undefined : json['cash_deal_score'],
    };
}

export function CashSummaryToJSON(value?: CashSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lowest_cash_rate': RawRateSummaryToJSON(value['lowestCashRate']),
        'cash_deal_score': value['cashDealScore'],
    };
}

