/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RoomInfo } from './RoomInfo';
import {
    RoomInfoFromJSON,
    RoomInfoFromJSONTyped,
    RoomInfoToJSON,
} from './RoomInfo';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * Summary of a room
 * @export
 * @interface RoomSummaryV3
 */
export interface RoomSummaryV3 {
    /**
     * 
     * @type {string}
     * @memberof RoomSummaryV3
     */
    roomId?: string;
    /**
     * The type of room. If there is an available direct booking rate, this is the direct booking room type. Otherwise, it will come from expedia.
     * @type {string}
     * @memberof RoomSummaryV3
     */
    roomType?: string;
    /**
     * A description of a room. May contain html tags
     * @type {string}
     * @memberof RoomSummaryV3
     */
    roomDescription?: string;
    /**
     * A code that uniquely defines a room for rooms at chains
     * @type {string}
     * @memberof RoomSummaryV3
     */
    roomCode?: string;
    /**
     * The room mapping id
     * @type {number}
     * @memberof RoomSummaryV3
     */
    roomMappingId?: number;
    /**
     * Views from the room
     * @type {Array<string>}
     * @memberof RoomSummaryV3
     */
    roomViews?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RoomSummaryV3
     */
    maxAdultsAllowed?: number;
    /**
     * Types of beds in the room
     * @type {Array<string>}
     * @memberof RoomSummaryV3
     */
    bedDescriptions?: Array<string>;
    /**
     * Size of the room in sq ft
     * @type {number}
     * @memberof RoomSummaryV3
     */
    areaSqFt?: number;
    /**
     * Array of links to room images
     * @type {Array<string>}
     * @memberof RoomSummaryV3
     */
    images?: Array<string>;
    /**
     * Array of room highlights/amenities
     * @type {Array<string>}
     * @memberof RoomSummaryV3
     */
    highlights?: Array<string>;
    /**
     * 
     * @type {Array<RawRateSummary>}
     * @memberof RoomSummaryV3
     */
    rates?: Array<RawRateSummary>;
    /**
     * 
     * @type {RoomInfo}
     * @memberof RoomSummaryV3
     */
    roomInfo?: RoomInfo;
}

/**
 * Check if a given object implements the RoomSummaryV3 interface.
 */
export function instanceOfRoomSummaryV3(value: object): value is RoomSummaryV3 {
    return true;
}

export function RoomSummaryV3FromJSON(json: any): RoomSummaryV3 {
    return RoomSummaryV3FromJSONTyped(json, false);
}

export function RoomSummaryV3FromJSONTyped(json: any, ignoreDiscriminator: boolean): RoomSummaryV3 {
    if (json == null) {
        return json;
    }
    return {
        
        'roomId': json['room_id'] == null ? undefined : json['room_id'],
        'roomType': json['room_type'] == null ? undefined : json['room_type'],
        'roomDescription': json['room_description'] == null ? undefined : json['room_description'],
        'roomCode': json['room_code'] == null ? undefined : json['room_code'],
        'roomMappingId': json['room_mapping_id'] == null ? undefined : json['room_mapping_id'],
        'roomViews': json['room_views'] == null ? undefined : json['room_views'],
        'maxAdultsAllowed': json['max_adults_allowed'] == null ? undefined : json['max_adults_allowed'],
        'bedDescriptions': json['bed_descriptions'] == null ? undefined : json['bed_descriptions'],
        'areaSqFt': json['area_sq_ft'] == null ? undefined : json['area_sq_ft'],
        'images': json['images'] == null ? undefined : json['images'],
        'highlights': json['highlights'] == null ? undefined : json['highlights'],
        'rates': json['rates'] == null ? undefined : ((json['rates'] as Array<any>).map(RawRateSummaryFromJSON)),
        'roomInfo': json['room_info'] == null ? undefined : RoomInfoFromJSON(json['room_info']),
    };
}

export function RoomSummaryV3ToJSON(value?: RoomSummaryV3 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'room_id': value['roomId'],
        'room_type': value['roomType'],
        'room_description': value['roomDescription'],
        'room_code': value['roomCode'],
        'room_mapping_id': value['roomMappingId'],
        'room_views': value['roomViews'],
        'max_adults_allowed': value['maxAdultsAllowed'],
        'bed_descriptions': value['bedDescriptions'],
        'area_sq_ft': value['areaSqFt'],
        'images': value['images'],
        'highlights': value['highlights'],
        'rates': value['rates'] == null ? undefined : ((value['rates'] as Array<any>).map(RawRateSummaryToJSON)),
        'room_info': RoomInfoToJSON(value['roomInfo']),
    };
}

