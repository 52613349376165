/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RawRateSummary } from './RawRateSummary';
import {
    RawRateSummaryFromJSON,
    RawRateSummaryFromJSONTyped,
    RawRateSummaryToJSON,
} from './RawRateSummary';

/**
 * 
 * @export
 * @interface PointsSummary
 */
export interface PointsSummary {
    /**
     * 
     * @type {RawRateSummary}
     * @memberof PointsSummary
     */
    lowestPointsRate: RawRateSummary;
    /**
     * The score of the deal. A value between 0 and 10, where 10 is a great deal.
     * @type {number}
     * @memberof PointsSummary
     */
    pointsDealScore: number;
}

/**
 * Check if a given object implements the PointsSummary interface.
 */
export function instanceOfPointsSummary(value: object): value is PointsSummary {
    if (!('lowestPointsRate' in value) || value['lowestPointsRate'] === undefined) return false;
    if (!('pointsDealScore' in value) || value['pointsDealScore'] === undefined) return false;
    return true;
}

export function PointsSummaryFromJSON(json: any): PointsSummary {
    return PointsSummaryFromJSONTyped(json, false);
}

export function PointsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointsSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'lowestPointsRate': RawRateSummaryFromJSON(json['lowest_points_rate']),
        'pointsDealScore': json['points_deal_score'],
    };
}

export function PointsSummaryToJSON(value?: PointsSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lowest_points_rate': RawRateSummaryToJSON(value['lowestPointsRate']),
        'points_deal_score': value['pointsDealScore'],
    };
}

