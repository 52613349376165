/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RateType } from './RateType';
import {
    RateTypeFromJSON,
    RateTypeFromJSONTyped,
    RateTypeToJSON,
} from './RateType';

/**
 * A request to validate a rate
 * @export
 * @interface RateValidationRequest
 */
export interface RateValidationRequest {
    /**
     * 
     * @type {number}
     * @memberof RateValidationRequest
     */
    vervotechPropertyId: number;
    /**
     * 
     * @type {number}
     * @memberof RateValidationRequest
     */
    roomId?: number;
    /**
     * 
     * @type {string}
     * @memberof RateValidationRequest
     */
    rateId: string;
    /**
     * 
     * @type {string}
     * @memberof RateValidationRequest
     */
    rateToken?: string;
    /**
     * 
     * @type {RateType}
     * @memberof RateValidationRequest
     */
    rateType: RateType;
    /**
     * 
     * @type {Date}
     * @memberof RateValidationRequest
     */
    checkin: Date;
    /**
     * 
     * @type {Date}
     * @memberof RateValidationRequest
     */
    checkout: Date;
    /**
     * 
     * @type {string}
     * @memberof RateValidationRequest
     */
    occupancy: string;
    /**
     * 
     * @type {boolean}
     * @memberof RateValidationRequest
     */
    test?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RateValidationRequest
     */
    prepareForBooking?: boolean;
}

/**
 * Check if a given object implements the RateValidationRequest interface.
 */
export function instanceOfRateValidationRequest(value: object): value is RateValidationRequest {
    if (!('vervotechPropertyId' in value) || value['vervotechPropertyId'] === undefined) return false;
    if (!('rateId' in value) || value['rateId'] === undefined) return false;
    if (!('rateType' in value) || value['rateType'] === undefined) return false;
    if (!('checkin' in value) || value['checkin'] === undefined) return false;
    if (!('checkout' in value) || value['checkout'] === undefined) return false;
    if (!('occupancy' in value) || value['occupancy'] === undefined) return false;
    return true;
}

export function RateValidationRequestFromJSON(json: any): RateValidationRequest {
    return RateValidationRequestFromJSONTyped(json, false);
}

export function RateValidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RateValidationRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'vervotechPropertyId': json['vervotech_property_id'],
        'roomId': json['room_id'] == null ? undefined : json['room_id'],
        'rateId': json['rate_id'],
        'rateToken': json['rate_token'] == null ? undefined : json['rate_token'],
        'rateType': RateTypeFromJSON(json['rate_type']),
        'checkin': (new Date(json['checkin'])),
        'checkout': (new Date(json['checkout'])),
        'occupancy': json['occupancy'],
        'test': json['test'] == null ? undefined : json['test'],
        'prepareForBooking': json['prepare_for_booking'] == null ? undefined : json['prepare_for_booking'],
    };
}

export function RateValidationRequestToJSON(value?: RateValidationRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'vervotech_property_id': value['vervotechPropertyId'],
        'room_id': value['roomId'],
        'rate_id': value['rateId'],
        'rate_token': value['rateToken'],
        'rate_type': RateTypeToJSON(value['rateType']),
        'checkin': ((value['checkin']).toISOString().substring(0,10)),
        'checkout': ((value['checkout']).toISOString().substring(0,10)),
        'occupancy': value['occupancy'],
        'test': value['test'],
        'prepare_for_booking': value['prepareForBooking'],
    };
}

