/* tslint:disable */
/* eslint-disable */
/**
 * Marco Search API
 * Marco Search API
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CashSummary } from './CashSummary';
import {
    CashSummaryFromJSON,
    CashSummaryFromJSONTyped,
    CashSummaryToJSON,
} from './CashSummary';
import type { PointsSummary } from './PointsSummary';
import {
    PointsSummaryFromJSON,
    PointsSummaryFromJSONTyped,
    PointsSummaryToJSON,
} from './PointsSummary';

/**
 * 
 * @export
 * @interface RatesSummary
 */
export interface RatesSummary {
    /**
     * 
     * @type {CashSummary}
     * @memberof RatesSummary
     */
    cashSummary?: CashSummary;
    /**
     * 
     * @type {PointsSummary}
     * @memberof RatesSummary
     */
    pointsSummary?: PointsSummary;
}

/**
 * Check if a given object implements the RatesSummary interface.
 */
export function instanceOfRatesSummary(value: object): value is RatesSummary {
    return true;
}

export function RatesSummaryFromJSON(json: any): RatesSummary {
    return RatesSummaryFromJSONTyped(json, false);
}

export function RatesSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RatesSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'cashSummary': json['cash_summary'] == null ? undefined : CashSummaryFromJSON(json['cash_summary']),
        'pointsSummary': json['points_summary'] == null ? undefined : PointsSummaryFromJSON(json['points_summary']),
    };
}

export function RatesSummaryToJSON(value?: RatesSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cash_summary': CashSummaryToJSON(value['cashSummary']),
        'points_summary': PointsSummaryToJSON(value['pointsSummary']),
    };
}

